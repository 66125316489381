import { Link, useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import {
  type DtoFeaturedItem,
  type DtoGamePack,
  type DtoPage,
  type DtoPageSection,
  type DtoProgram,
  type DtoTag,
  EnumsPageName,
  EnumsPageRowLayout,
} from '@lp-lib/api-service-client/public';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import {
  ErrorMessage,
  type GameLikeFilterOptions,
  useGameLikeWorkspace,
} from '../../../src/components/Game/GameCenter';
import { GamePackFeaturedActionsPublicHome } from '../../../src/components/Game/GamePack/GamePackFeaturedActions';
import { DoubleRightArrow } from '../../../src/components/icons/Arrows';
import { TeamsIcon } from '../../../src/components/icons/TeamsIcon';
import {
  useIsMyOrgSlackConnected,
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../../../src/components/Organization/hooks/organization';
import { OrgSubscriptionUpgradeButton } from '../../../src/components/Organization/OrgSubscription';
import { UseProgramButton } from '../../../src/components/Program/UseProgramButton';
import { SlackInstallButton } from '../../../src/components/Slack';
import { TagUtils } from '../../../src/components/Tagging/utils';
import { getGamePacksByTagId } from '../../../src/pages/GamePack/Collections';
import { type Organization, TaggedObjectType } from '../../../src/types';
import { type GamePack } from '../../../src/types/game';
import { fromDTOGamePack } from '../../../src/utils/api-dto';
import { type PageSectionBodyProgramsProps } from '../DynamicPage/PageSectionBody';
import { PageSectionList } from '../DynamicPage/PageSectionList';
import {
  type PageSectionRowFeaturedCarouselContainerProps,
  type PageSectionRowFeaturedTagContainerProps,
  type PageSectionRowTagCollectionContainerProps,
} from '../DynamicPage/PageSectionRow';
import { PageDisplayName } from '../DynamicPage/utils';
import { FeaturedCard, FeaturedSlice } from '../Featured/FeaturedCard';
import { FeaturedList } from '../Featured/FeaturedList';
import {
  ProgramDetailsModal,
  useSearchParamProgramId,
} from '../Programs/Details/ProgramDetailsCard';
import { ProgramDetailsUsedBy } from '../Programs/Details/ProgramDetailsUsedBy';
import { TagCard } from '../Tag/TagCard';
import { HomeGamePackCard } from './HomeGamePack';
import { PublicLibraryBackground } from './PublicLibraryBackground';
import { PublicLibraryBreadcrumbs } from './PublicLibraryBreadcrumbs';
import {
  encodeFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysMain,
} from './PublicLibraryFilter';
import {
  PublicLibraryGamePackCarousel,
  PublicLibraryGamePackCarouselLoading,
  PublicLibraryGamePackList,
  PublicLibraryGamePackListLoading,
  PublicLibraryTagCarousel,
  PublicLibraryTagList,
} from './PublicLibraryLayout';
import { PublicLibraryProgramsSection } from './PublicLibraryPrograms';

export function HomeProgramDetailsSideAction(props: {
  organization: Organization;
  program: DtoProgram;
}) {
  const { organization, program } = props;

  const featureChecker = useMyOrganizationFeatureChecker();
  const isSlackConnected = useIsMyOrgSlackConnected();
  const canAccess = featureChecker.canAccessProgram(props.program.id);

  return (
    <div className='w-full p-2 pt-6 bg-main-layer rounded-xl'>
      <div className='text-center text-xl font-bold text-tertiary'>
        Get Started for Free
      </div>
      <div className='mt-7 flex flex-col gap-2.5'>
        {!canAccess ? (
          <OrgSubscriptionUpgradeButton
            className={`w-full h-15 rounded p-0.5`}
            innerClassName='rounded'
          />
        ) : !isSlackConnected ? (
          <>
            <SlackInstallButton
              text='Install with Slack'
              className='btn-secondary rounded w-full h-15 flex justify-center items-center gap-2'
            />

            <button
              type='button'
              disabled
              className='btn-secondary rounded w-full h-15 flex justify-center items-center gap-2'
            >
              <TeamsIcon className='w-5 h-5 fill-current' />
              <p>MS Teams Coming soon...</p>
            </button>
          </>
        ) : (
          <UseProgramButton
            org={organization}
            program={program}
            className='btn-delete rounded w-full h-15 flex justify-center items-center gap-2'
          />
        )}
      </div>
    </div>
  );
}

function HomePrograms(
  props: PageSectionBodyProgramsProps & HomePublicLibraryIndexProps
) {
  const { pageName, section, sectionIndex, programs, analytics } = props;

  const organization = useMyOrganization();
  const [programId, setProgramId] = useSearchParamProgramId();

  const program = programs.find((p) => p.id === programId);

  if (!organization) return null;

  const openProgram = (program: DtoProgram) => {
    analytics?.trackProgramCardClicked({
      pageName: PageDisplayName(pageName),
      sectionIndex,
      sectionTitle: section.title,
      programId: program.id,
      programName: program.name,
    });
    setProgramId(program.id);
  };

  return (
    <>
      <PublicLibraryProgramsSection programs={programs} onClick={openProgram} />

      {program && (
        <ProgramDetailsModal
          key={`program-details-modal-${program.id}`}
          program={program}
          anonymous={false}
          relatedPrograms={programs.filter((p) => p.id !== program.id)}
          breadcrumbs={
            <PublicLibraryBreadcrumbs
              items={[
                {
                  kind: 'button',
                  label: 'Explore',
                  onClick: () => setProgramId(null),
                },
                {
                  kind: 'button',
                  label: 'Programs',
                  onClick: () => {
                    setProgramId(null);
                  },
                },
                { kind: 'noop', label: program.name },
              ]}
            />
          }
          sideAction={
            <HomeProgramDetailsSideAction
              organization={organization}
              program={program}
            />
          }
          activelyUsedBy={
            organization ? (
              <ProgramDetailsUsedBy
                program={program}
                organization={organization}
              />
            ) : null
          }
          onClose={() => setProgramId(null)}
          onClickProgram={(program) => setProgramId(program.id)}
        />
      )}
    </>
  );
}

function HomeFeaturedCarousel(
  props: PageSectionRowFeaturedCarouselContainerProps &
    HomePublicLibraryIndexProps
) {
  const {
    pageName,
    section,
    row,
    rowIndex,
    sectionIndex,
    featuredItems,
    analytics,
  } = props;

  const navigate = useNavigate();
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [filterOptions, setFilterOptions] = useState<
    Partial<GameLikeFilterOptions>
  >({});

  const handleClickPack = (pack: DtoGamePack, itemIndex: number) => {
    analytics?.trackLibraryGamePackClicked({
      pageName: PageDisplayName(pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'featured-carousel',
      itemIndex,
      packId: pack.id,
      packName: pack.name,
    });
    setActiveGamePack(fromDTOGamePack(pack));
  };

  const handleViewPack = (pack: DtoGamePack, itemIndex: number) => {
    analytics?.trackLibraryGamePackViewed(
      {
        pageName: PageDisplayName(pageName),
        sectionIndex,
        sectionTitle: section.title,
        rowIndex,
        rowType: 'featured-carousel',
        itemIndex,
        packId: pack.id,
        packName: pack.name,
      },
      `library-game-pack-viewed:${section.id}:${row.id}:${pack.id}`
    );
  };

  const handleViewTag = (tag: DtoTag, itemIndex: number) => {
    analytics?.trackLibraryCategoryCardViewed(
      {
        pageName: PageDisplayName(pageName),
        sectionIndex,
        sectionTitle: section.title,
        rowIndex,
        rowType: 'featured-carousel',
        itemIndex,
        categoryId: tag.id,
        categoryName: tag.name,
      },
      `library-category-viewed:${section.id}:${row.id}:${tag.id}`
    );
  };

  const handleClickTag = (tag: DtoTag, index: number) => {
    analytics?.trackLibraryCategoryCardClicked({
      pageName: PageDisplayName(pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'featured-carousel',
      itemIndex: index,
      categoryId: tag.id,
      categoryName: tag.name,
    });
    navigate($path('/home/tags/:slug', { slug: tag.slug }));
  };

  return (
    <div className='w-full flex flex-col items-center gap-5'>
      <div className='w-full max-w-[1380px]'>
        <FeaturedList
          hideBullets
          items={featuredItems}
          renderSlide={(props) => <FeaturedSlice {...props} />}
          renderCard={(props) => (
            <FeaturedCard
              {...props}
              onGamePackClick={(pack) => handleClickPack(pack, props.index)}
              renderGamePackAction={(pack) => (
                <GamePackFeaturedActionsPublicHome
                  pack={fromDTOGamePack(pack)}
                />
              )}
              onGamePackViewed={(pack) => handleViewPack(pack, props.index)}
              onTagViewed={(tag) => handleViewTag(tag, props.index)}
              onTagClick={(tag) => handleClickTag(tag, props.index)}
            />
          )}
        />
      </div>

      <PublicLibraryFilter
        showMenuKeys={PublicLibraryFilterKeysMain}
        filterOptions={filterOptions}
        withApplyButton
        onChange={setFilterOptions}
        onApply={(options) => {
          const params = encodeFilterOptions(options);
          navigate($path('/home/search', params));
        }}
      />
    </div>
  );
}

function HomeFeaturedTag(
  props: PageSectionRowFeaturedTagContainerProps & HomePublicLibraryIndexProps
) {
  const { pageName, tag, section, sectionIndex, row, rowIndex, analytics } =
    props;

  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [entered, setEntered] = useState(false);
  const { data: packs, mutate } = useSWR(
    entered ? ['/game-packs', tag.id] : null,
    async () => {
      return getGamePacksByTagId(tag.id, true);
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleClickPack = (pack: GamePack, itemIndex: number) => {
    if (pageName === EnumsPageName.PageNameGeneralLearning) {
      // do nothing.
      return;
    }
    analytics?.trackLibraryGamePackClicked({
      pageName: PageDisplayName(pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'featured-tag',
      categoryId: tag.id,
      categoryName: tag.name,
      itemIndex,
      packId: pack.id,
      packName: pack.name,
    });
    setActiveGamePack(pack);
  };

  const handleViewPack = (pack: GamePack, itemIndex: number) => {
    analytics?.trackLibraryGamePackViewed(
      {
        pageName: PageDisplayName(pageName),
        sectionIndex,
        sectionTitle: section.title,
        rowIndex,
        rowType: 'featured-tag',
        categoryId: tag.id,
        categoryName: tag.name,
        itemIndex,
        packId: pack.id,
        packName: pack.name,
      },
      `library-game-pack-viewed:${section.id}:${row.id}:${pack.id}`
    );
  };

  const showHeader =
    pageName !== EnumsPageName.PageNameLiveOtp &&
    pageName !== EnumsPageName.PageNameLiveLoggedIn &&
    pageName !== EnumsPageName.PageNameGeneralLearning;

  return (
    <div className='w-full'>
      <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll></Waypoint>

      {showHeader && (
        <div className='flex items-center gap-8 group'>
          <Link
            to={$path('/home/tags/:slug', { slug: tag.slug })}
            onClick={() => analytics?.trackLibraryCategoryClicked(tag.name)}
            className='btn flex items-center gap-8'
          >
            <p className={`text-xl font-bold`}>{tag.name}</p>
            <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
              <p className='text-sms'>View All</p> <DoubleRightArrow />
            </div>
          </Link>
        </div>
      )}

      {!packs &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackListLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ) : (
          <PublicLibraryGamePackCarouselLoading
            count={TagUtils.GetObjectsCount(
              tag,
              TaggedObjectType.PrimeGamePack
            )}
          />
        ))}
      {packs && packs.length === 0 && (
        <ErrorMessage text={`No game packs found`} handleRetry={() => mutate} />
      )}
      {packs &&
        packs.length > 0 &&
        (row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
          <PublicLibraryGamePackList
            packs={packs || []}
            renderCard={(pack, index) => (
              <HomeGamePackCard
                pack={pack}
                onClick={() => handleClickPack(pack, index)}
                onVisible={() => handleViewPack(pack, index)}
                showLearningHover={
                  pageName === EnumsPageName.PageNameGeneralLearning
                }
              />
            )}
          />
        ) : (
          <PublicLibraryGamePackCarousel
            packs={packs || []}
            renderCard={(pack, index) => (
              <HomeGamePackCard
                pack={pack}
                onClick={() => handleClickPack(pack, index)}
                onVisible={() => handleViewPack(pack, index)}
                showLearningHover={
                  pageName === EnumsPageName.PageNameGeneralLearning
                }
              />
            )}
          />
        ))}
    </div>
  );
}

function HomeFeaturedTagCollection(
  props: PageSectionRowTagCollectionContainerProps & HomePublicLibraryIndexProps
) {
  const {
    pageName,
    collection,
    section,
    sectionIndex,
    row,
    rowIndex,
    analytics,
  } = props;

  const navigate = useNavigate();

  const handleView = (tag: DtoTag, itemIndex: number) => {
    analytics?.trackLibraryCategoryCardViewed(
      {
        pageName: PageDisplayName(pageName),
        sectionIndex,
        sectionTitle: section.title,
        rowIndex,
        rowType: 'tag-collection',
        collectionName: collection.name,
        itemIndex,
        categoryId: tag.id,
        categoryName: tag.name,
      },
      `library-category-card-viewed:${section.id}:${row.id}:${tag.id}`
    );
  };

  const handleClick = (tag: DtoTag, itemIndex: number) => {
    analytics?.trackLibraryCategoryCardClicked({
      pageName: PageDisplayName(pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'tag-collection',
      collectionName: collection.name,
      itemIndex,
      categoryId: tag.id,
      categoryName: tag.name,
    });
    navigate($path('/home/tags/:slug', { slug: tag.slug }));
  };

  return (
    <div className='w-full'>
      <h3 className={`text-xl font-bold`}>{collection.name}</h3>

      {row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
        <PublicLibraryTagList
          tags={collection.tags || []}
          renderCard={(tag, index) => (
            <TagCard
              tag={tag}
              onClick={() => handleClick(tag, index)}
              onViewed={() => handleView(tag, index)}
            />
          )}
        />
      ) : (
        <PublicLibraryTagCarousel
          tags={collection.tags || []}
          renderCard={(tag, index) => (
            <TagCard
              tag={tag}
              onClick={() => handleClick(tag, index)}
              onViewed={() => handleView(tag, index)}
            />
          )}
        />
      )}
    </div>
  );
}

export interface HomePublicLibraryIndexProps {
  pageName: EnumsPageName;
  page: DtoPage;
  programs: DtoProgram[];
  featuredItems: DtoFeaturedItem[];
  hideCategoriesHeader?: boolean;
  setFocusedSection?: (section: DtoPageSection) => void;
  analytics?: AppAnalytics;
}

export function HomePublicLibraryIndex(props: HomePublicLibraryIndexProps) {
  return (
    <div className='relative w-full'>
      <PublicLibraryBackground page={props.page} />

      <div className='w-full relative'>
        <PageSectionList
          pageName={props.pageName}
          sections={props.page.sections || []}
          onFocusedSectionChange={(section) =>
            props.setFocusedSection && props.setFocusedSection(section)
          }
          renderPrograms={(p) => <HomePrograms {...p} {...props} />}
          renderFeaturedCarousel={(p) => (
            <HomeFeaturedCarousel {...p} {...props} />
          )}
          renderFeaturedTag={(p) => <HomeFeaturedTag {...p} {...props} />}
          renderTagCollection={(p) => (
            <HomeFeaturedTagCollection {...p} {...props} />
          )}
          analytics={props.analytics}
        />
      </div>
    </div>
  );
}
