import { DefaultGamePackCardBadges } from '../../../src/components/Game/GamePack/GamePackCard';
import { GamePackCardBottomAccessory } from '../../../src/pages/GamePack/GamePackCardBottomAccessory';
import { type GamePack } from '../../../src/types/game';
import { GamePackCard } from '../GamePack/GamePackCollection';

export function HomeGamePackCard(props: {
  pack: GamePack;
  onClick?: () => void;
  onVisible?: () => void;
  showLearningHover?: boolean;
}) {
  const { pack, onClick, onVisible } = props;

  return (
    <GamePackCard
      onViewed={onVisible}
      gamePack={pack}
      onClick={onClick}
      badges={<DefaultGamePackCardBadges gamePack={pack} />}
      bottomAccessory={<GamePackCardBottomAccessory pack={pack} />}
      styles={{
        size: 'w-full',
        badgesTranslateClassName: '-translate-y-1/4',
      }}
      showLearningHover={props.showLearningHover}
    />
  );
}
